<template>
  <div v-loading="loading" class="balance-alignment-task" @click="onClickOutside">
    <SingularTaskLayout
      :title="$t('tasks.balanceAlignmentTask.title', { date: balanceAlignmentToDate })"
      :task="currentTask"
      :is-loading-task="loading"
      :validate-task-before-complete-hook="validateTaskHook"
      :requests-target-businesses-ids="[supplier.id, currentTask.businessId]"
      :request-templates="templates"
      :is-actions-disabled="actionsDisabled"
      :is-skip-action-disabled="skipActionDisabled"
      @complete-task="completedTask"
      @skip-task="onTaskSkip"
      @template-selected="onTemplateSelected"
    >
      <template #content>
        <div class="details-container p-6 mb-7">
          <div class="d-flex flex-row align-items-center">
            <el-popover :value="contactPopover" trigger="manual" placement="bottom" popper-class="p-0">
              <ContactCard
                v-if="contactUser"
                :user="contactUser"
                :customer="business"
                :business-id="currentTask.businessId"
                :supplier="supplier"
                :token="token"
              />
              <template #reference>
                <Button
                  type="link"
                  class="text-typography-primary p-0"
                  @click.stop="openContactPopover({ type: 'tenant', payload: { worksAt: business.id } })"
                >
                  <h3>{{ business?.name }}</h3>
                </Button>
                <ExchangeIcon class="mx-1" />
                <Button
                  type="link"
                  class="text-typography-primary p-0"
                  @click.stop="
                    openContactPopover({
                      type: 'supplier',
                      payload: { worksAt: supplier.id, businessId: currentTask.businessId },
                    })
                  "
                >
                  <h3>{{ supplier.name }}</h3>
                </Button>
              </template>
            </el-popover>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.details.reconciliationsInfo') }}</p>
            <Button
              v-for="reconciliation in reconciliations"
              :key="reconciliation.id"
              type="link"
              class="text-typography-primary p-0 text-decoration-underline"
              @click="handleReconciliationDateClick(reconciliation.id)"
            >
              {{ formatReconciliationDate(reconciliation.periodStart, reconciliation.periodEnd) }}
            </Button>
          </div>
          <div v-if="currentTask?.data?.reason" class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.details.reason') }}</p>
            <p>{{ $t(`tasks.reasonsForBalanceAsignmentTask.${currentTask?.data.reason}`) }}</p>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.details.comments') }}</p>
            <p>{{ taskComment }}</p>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.details.reconciliationSettings') }}</p>
            <div class="d-flex gap-2 align-items-center">
              <div v-if="reconciliationTemplate.note">
                <p v-if="reconciliationTemplate.note.length < 80">{{ reconciliationTemplate.note }}</p>
                <p v-else style="width: 552px">
                  <TruncatedText>{{ reconciliationTemplate.note }}</TruncatedText>
                </p>
              </div>
              <div>
                <Button type="link" class="text-typography-primary p-0 link" @click="openReconciliationSettings"
                  >{{ $t('tasks.details.showSettings') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="latestReconciliation.id" class="d-flex justify-content-between gap-4">
          <ReconciliationInfoCard
            v-if="supplier && business"
            class="col-6 mb-7"
            :reconciliation="latestReconciliation"
            :reconciliation-refetch="reconciliationsRefetch"
            :supplier="supplier"
            :customer="business"
          />
          <StatusReflectionCard
            class="mb-7 flex-fill"
            :status-reflection="statusReflection"
            :reconciliation-status="latestReconciliationStatus"
            :status-override="latestReconciliationStatusOverride"
            :reconciliation-id="latestReconciliation.id"
            :business-id="currentTask.businessId"
            :supplier="supplier"
            :reconciliation="latestReconciliation"
            :is-admin="isAdmin"
            :tasks="tasks"
            @update-task="taskUpdate"
          />
        </div>
        <div class="d-flex gap-7">
          <SupplierPerspective
            :supplier="supplier"
            :documents="documents"
            :documents-loading="documentsLoading"
            class="col-6 sticky-top"
            style="z-index: 1"
          />
          <div class="flex-fill overflow-auto">
            <h3 class="mb-5">{{ $t('tasks.balanceAlignmentTask.content.actions') }}</h3>
            <div class="accordion accordion-flush">
              <div v-for="(value, action) in actions" :key="action" class="accordion-item">
                <div :id="`${action}-accordion-item`" class="accordion-item">
                  <div
                    class="py-4 d-flex justify-content-between"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="action === ACTIONS.BALANCE_VERIFICATION ? null : `#${action}`"
                    aria-expanded="false"
                    :aria-controls="`${action}`"
                  >
                    <div class="d-flex gap-4 align-items-center">
                      <div
                        class="radio-button-wrapper"
                        data-bs-toggle="collapse"
                        @mouseover="activeHoverToggleArea = action"
                        @mouseleave="activeHoverToggleArea = null"
                        @click="handleActionStateChangeClick(action)"
                      >
                        <div v-if="value">
                          <div class="checked-background d-flex align-items-center justify-content-center">
                            <CheckXsIcon v-if="value" :fill="checkFillColor" />
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-if="activeHoverToggleArea === action"
                            class="unchecked-background-hover d-flex align-items-center justify-content-center"
                          >
                            <CheckXsIcon :fill="checkFillColor" />
                          </div>
                          <div v-else class="unchecked-background d-flex align-items-center justify-content-center">
                            <div class="inner-white-circle" />
                          </div>
                        </div>
                      </div>
                      <p :class="{ 'text-decoration-line-through': value }">
                        {{
                          action === ACTIONS.MISSING_INVOICES_COMPLETION
                            ? $t(`tasks.balanceAlignmentTask.content.${action}`, { count: missingBillings.length })
                            : $t(`tasks.balanceAlignmentTask.content.${action}`)
                        }}
                      </p>
                    </div>
                    <ChevronIcon :direction="isCollapsed(action) ? 'down' : 'up'" class="text-typography-secondary" />
                  </div>
                  <div
                    :id="`${action}`"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`${action}-accordion-item`"
                  >
                    <div class="my-4 me-4">
                      <template v-if="action === ACTIONS.BALANCE_VERIFICATION"></template>
                      <template v-if="action === ACTIONS.MISSING_INVOICES_COMPLETION">
                        <MissingBillingsTable
                          v-if="!loading"
                          :business-id="currentTask.businessId"
                          :supplier-id="supplierId"
                          :missing-billings="missingBillings"
                          @open-document="handleOpenDocument"
                          @request-missing-events="onRequestMissingEvents"
                        />
                      </template>
                      <template v-if="action === ACTIONS.BALANCE_ALIGNMENT">
                        <div class="d-flex align-items-end flex-column">
                          <Button
                            type="secondary"
                            :disabled="!!balancePayment"
                            @click="balancePaymentModalSupplier = supplier"
                          >
                            <SettingsIcon /> {{ $t('tasks.balanceAlignmentTask.content.initialBalanceSettings') }}
                          </Button>
                        </div>
                        <Tabs :tabs="balanceAlignmentTabs" :active-tab.sync="balanceAlignmentActiveTab" class="mb-4" />
                        <ClarityAgedDebtorsReport
                          v-if="balanceAlignmentActiveTab === 0 && supplier.id"
                          :supplier-id="supplier.id"
                          :business-id="currentTask.businessId"
                        />
                        <RestaurantPerspective
                          v-if="balanceAlignmentActiveTab === 1"
                          :supplier="supplier"
                          :restaurant="business"
                          :show-title="false"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-7" />
        <div class="status-reflection pe-3 my-7">
          <h4>
            {{ $t('tasks.reconciliationTask.statusReflection') }}
          </h4>
          <el-form ref="statusReflection" :model="statusReflectionForm" :show-message="false">
            <el-form-item prop="update" class="my-4" required>
              <el-radio-group
                v-model="statusReflectionForm.update"
                class="d-flex align-items-center gap-5"
                :class="{ 'radio-red-border': validationError }"
              >
                <el-radio :label="true" class="mx-0 d-flex">
                  <p>{{ $t('tasks.reconciliationTask.update') }}</p>
                </el-radio>
                <el-radio :label="false" class="d-flex">
                  <p>{{ $t('tasks.reconciliationTask.noUpdate') }}</p>
                </el-radio>
              </el-radio-group>
              <div v-if="validationError" class="mt-1">
                <div class="d-flex align-items-center">
                  <ErrorFullIcon />
                  <p class="red me-1">{{ $t('tasks.reconciliationTask.noChoiceMade') }}</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="statusReflectionForm.reason"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 10 }"
                :disabled="statusReflectionDisabled"
              />
            </el-form-item>
          </el-form>
          <p class="text-typography-secondary mt-2">
            {{ $t('tasks.reconciliationTask.statusReflectionDescription') }}
          </p>
        </div>
        <div class="pe-3 my-7 mt-3 status-reflection">
          <h4>
            {{ $t('reconciliation.balanceAlignmentModal.title') }}
          </h4>
          <p class="text-typography-primary mt-4">
            <el-checkbox v-model="isBalanceValidationChecked" class="ms-2" />
            {{
              $t('reconciliation.balanceAlignmentModal.content', { date: formatDate(latestReconciliation?.periodEnd) })
            }}
            {{ formatToCurrency(balanceAlignmentAmount) ?? '' }}
          </p>
          <p class="mt-3 fw-bold">{{ $t('reconciliation.balanceAlignmentModal.notes') }}</p>
          <el-input
            v-model="balanceAlignmentNote"
            type="textarea"
            class="mt-1"
            :disabled="!isBalanceValidationChecked"
            :autosize="{ minRows: 2, maxRows: 2 }"
          />
        </div>
      </template>
      <template #play-mode-indicator>
        <slot name="play-mode-indicator"></slot>
      </template>
    </SingularTaskLayout>
    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
    <BalancePaymentModal
      v-if="balancePaymentModalSupplier"
      :supplier="balancePaymentModalSupplier"
      :business-id="business.id"
      @close="balancePaymentModalSupplier = null"
      @balance-payment-create="balancePaymentsRefetch"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="handleChatOpen"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="business"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />
  </div>
</template>

<script>
import { ref, computed, reactive, watch, getCurrentInstance } from 'vue';
import { reject, isNil, head, partition } from 'ramda';
import { DateTime } from 'luxon';
import Big from 'big.js';

import { Button, TruncatedText, Tabs } from '@/modules/core';
import { ExchangeIcon, ErrorFullIcon, ChevronIcon, CheckXsIcon, SettingsIcon } from '@/assets/icons';
import {
  ReconciliationModal,
  ReconciliationSettingsModal,
  ReconciliationInfoCard,
  StatusReflectionCard,
  useReconciliationTemplate,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  useMissingEvents,
  RECONCILIATION_STATUSES,
} from '@/modules/reconciliation';
import { ContactCard } from '@/modules/contact';
import {
  BalancePaymentModal,
  SupplierPerspective,
  RestaurantPerspective,
  ClarityAgedDebtorsReport,
  useBalancePayments,
  PAYABLES_QUERY,
  usePayables,
} from '@/modules/payment';
import { DocumentModal } from '@/modules/documentModal';
import { useTenancy, useUser } from '@/modules/auth';
import { useSupplier } from '@/modules/suppliers';
import { useChatModal } from '@/modules/chatModal';
import { DOCUMENT_TYPES, CONSOLIDATED_DOCS, INVOICE_DOCS } from '@/modules/document/types';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useUncertainBillings } from '@/modules/billing';
import { useLast12Documents } from '@/modules/document/compositions';

import {
  MissingBillingsTable,
  validateStatusReflection,
  useContinueToNextTask,
  SingularTaskLayout,
} from './components';
import { useActivityTrack } from '../compositions';
import { useReconciliations, useTasks, useBusinessById, useUpdateReconciliation } from './composition';
import { usePatchTaskDirect } from './composition/useTasks';

const ACTIONS = {
  BALANCE_VERIFICATION: 'balanceVerification',
  MISSING_INVOICES_COMPLETION: 'missingInvoicesCompletion',
  BALANCE_ALIGNMENT: 'balanceAlignment',
};

export const REQUEST_TEMPLATES_TYPE = {
  balanceAlignment: `balanceAlignment`,
  balanceAlignmentReconciliationStatement: `balanceAlignmentReconciliationStatement`,
  balanceAlignmentCheckNewInvoices: `balanceAlignmentCheckNewInvoices`,
  balanceAlignmentMissingDocuments: `balanceAlignmentMissingDocuments`,
  other: `other`,
};

const VALIDATION_ERRORS = {
  STATUS_REFLECTION_EMPTY: 'tasks.balanceAlignmentTask.statusReflectionEmpty',
};

export default {
  components: {
    Button,
    Tabs,
    TruncatedText,
    ExchangeIcon,
    ContactCard,
    ErrorFullIcon,
    DocumentModal,
    BalancePaymentModal,
    ReconciliationModal,
    ReconciliationInfoCard,
    StatusReflectionCard,
    ReconciliationSettingsModal,
    SupplierPerspective,
    RestaurantPerspective,
    ClarityAgedDebtorsReport,
    MissingBillingsTable,
    ChevronIcon,
    CheckXsIcon,
    SettingsIcon,
    SingularTaskLayout,
  },
  props: {
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
  },
  setup(props) {
    const currentTask = ref(props.task);
    const taskId = ref(props.task.id);
    watch(
      () => props.task,
      (newTask, oldTask) => {
        if (newTask.id !== oldTask.id) {
          currentTask.value = newTask;
          taskId.value = newTask.id;
        }
      }
    );

    const root = getCurrentInstance().proxy;
    useActivityTrack(taskId);
    const { formatCentsToCurrency, formatToCurrency } = useCurrency();

    const actions = reactive({
      balanceVerification: currentTask.value?.data?.subTasks?.balanceVerification ?? false,
      missingInvoicesCompletion: currentTask.value?.data?.subTasks?.missingInvoicesCompletion ?? false,
      balanceAlignment: currentTask.value?.data?.subTasks?.balanceAlignment ?? false,
    });
    const { token } = useTenancy();
    const { isAdmin } = useUser();
    const supplierId = computed(() => currentTask.value?.data?.supplierId);
    const { supplier } = useSupplier(computed(() => currentTask.value?.data?.supplierId));
    const { business, loading: businessLoading } = useBusinessById(computed(() => currentTask.value?.businessId));

    const { documents, loading: documentsLoading } = useLast12Documents(
      computed(() => ({
        businessId: currentTask.value.businessId,
        supplierId: supplier.value.id,
        types: [DOCUMENT_TYPES.RECONCILIATION_STATEMENT, DOCUMENT_TYPES.AGED_DEBTORS_REPORT],
      }))
    );

    const layoutLoading = ref(false);

    const { reconciliationTemplate, refetch: reconciliationTemplateRefetch } = useReconciliationTemplate(
      computed(() => ({
        businessId: currentTask.value.businessId,
        supplierId: supplier.value.id,
      }))
    );
    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const {
      reconciliations,
      loading: reconciliationsLoading,
      refetch: reconciliationsRefetch,
    } = useReconciliations(
      computed(() => ({
        businessId: currentTask.value.businessId,
        ids: currentTask.value?.data?.reconciliationIds,
      }))
    );

    const reconciliationsSortedByPeriod = computed(() =>
      [...(reconciliations.value || [])].sort((a, b) => new Date(a.periodStart) - new Date(b.periodStart))
    );

    const notApprovedBalanceAlignmentReconciliations = computed(() =>
      reconciliationsSortedByPeriod.value.filter(({ status: { details } }) => {
        const snapshotDetail = details.find(({ key }) => key === 'snapshot');

        return (
          snapshotDetail?.details?.find(({ key }) => key === 'balanceAlignment')?.status !==
          RECONCILIATION_STATUSES.APPROVED
        );
      })
    );

    const latestReconciliation = computed(
      () =>
        notApprovedBalanceAlignmentReconciliations.value[notApprovedBalanceAlignmentReconciliations.value.length - 1]
    );

    const { closeChatModal, openChatModal } = useChatModal();

    const { updateReconciliation } = useUpdateReconciliation();

    const { balancePayments, refetch: balancePaymentsRefetch } = useBalancePayments(
      () => ({ businessId: currentTask.value.businessId, supplierId: supplier.value.id }),
      () => ({ enabled: !!currentTask.value.businessId && !!supplier.value.id })
    );

    const { patchTask } = usePatchTaskDirect();
    const { continueToNextTask } = useContinueToNextTask();

    const notApprovedBalanceAlignmentReconciliationsWithBalance = ref([]);

    watch(
      [notApprovedBalanceAlignmentReconciliations, balancePayments],
      async ([newReconciliations]) => {
        if (newReconciliations.length && supplier.value.id === head(newReconciliations).supplierId) {
          await Promise.all(
            newReconciliations.map((reconciliation) => {
              const { businessId, supplierId, periodEnd } = reconciliation;
              return root.$apollo
                .query({ query: PAYABLES_QUERY, variables: { businessId, supplierId, toDate: periodEnd } })
                .then(({ data }) => ({
                  ...reconciliation,
                  balance: data.payables?.nodes[0]?.balance ?? 0,
                }));
            })
          ).then((data) => (notApprovedBalanceAlignmentReconciliationsWithBalance.value = data));
        }
      },
      { immediate: true }
    );

    const periodEnd = computed(() => reconciliations.value[0].periodEnd);

    const {
      handleReconciliationTasks,
      supplierCreationTasks,
      uncertainBillingTasks,
      balanceAlignmentTasks,
      refetch: tasksRefetch,
    } = useTasks(computed(() => ({ businessId: currentTask.value?.businessId })));
    const { uncertainBillings } = useUncertainBillings(computed(() => ({ businessId: currentTask.value?.businessId })));

    const { payables } = usePayables(
      computed(() => ({
        businessId: currentTask.value?.businessId,
        supplierId: supplierId.value,
        toDate: latestReconciliation.value?.periodEnd,
      })),
      { enabled: currentTask.value?.businessId && supplierId.value && latestReconciliation.value?.periodEnd }
    );

    const balanceAlignmentAmount = computed(() => payables.value[0]?.balance ?? 0);

    const { missingEvents } = useMissingEvents(
      () => ({ businessId: currentTask.value?.businessId, supplierId: supplierId.value }),
      () => ({ enabled: !!currentTask.value?.businessId && !!supplierId.value })
    );
    const missingBillings = computed(() =>
      missingEvents.value.filter(({ possibleTypes }) =>
        possibleTypes.some((type) =>
          [...CONSOLIDATED_DOCS, ...INVOICE_DOCS, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(type)
        )
      )
    );

    return {
      isBalanceValidationChecked: ref(false),
      VALIDATION_ERRORS,
      missingBillings,
      periodEnd,
      balanceAlignmentNote: ref(''),
      balanceAlignmentAmount,
      formatCentsToCurrency,
      formatToCurrency,
      layoutLoading,
      taskId,
      currentTask,
      actions,
      loading: computed(
        () => props.isLoadingTask.value || layoutLoading.value || reconciliationsLoading.value,
        businessLoading.value
      ),
      contactUser: ref(null),
      contactPopover: ref(false),
      business,
      token,
      supplier,
      supplierId,
      validationError: ref(false),
      displayedDocumentId: ref(null),
      balancePaymentModalSupplier: ref(null),
      selectedReconciliationId: ref(null),
      reconciliationTemplate,
      isSupplierTemplateDefined: computed(() => !!reconciliationTemplate.value?.supplierId),
      reconciliationTemplateRefetch,
      createReconciliationTemplate,
      updateReconciliationTemplate,
      reconciliations: computed(() => notApprovedBalanceAlignmentReconciliationsWithBalance.value),
      latestReconciliation: computed(() =>
        latestReconciliation.value?.id
          ? {
              ...latestReconciliation.value,
              totalBilledAmount:
                latestReconciliation.value?.billedAmounts
                  .reduce((sum, billedAmount) => sum.add(billedAmount.amount), new Big(0))
                  .toNumber() ?? 0,
              totalPaidAmount:
                latestReconciliation.value?.paidAmounts
                  .reduce((sum, paidAmount) => sum.add(paidAmount.amount), new Big(0))
                  .toNumber() ?? 0,
            }
          : {}
      ),
      templates: ref(
        Object.values(REQUEST_TEMPLATES_TYPE).map((type) => ({
          type,
          selected: false,
          title: root.$t(`requests.requestsButton.popover.templates.${type}.title`),
          text: root.$t(`requests.requestsButton.popover.templates.${type}.text`, {
            date: '',
          }),
        }))
      ),
      reconciliationsRefetch,
      latestReconciliationStatus: computed(() => latestReconciliation.status),
      latestReconciliationStatusOverride: computed(() => latestReconciliation.statusOverride),
      statusReflectionForm: reactive({
        update: null,
        reason: latestReconciliation.value?.statusReflection?.reason ?? '',
      }),
      pickedReconciliationId: ref(null),
      closeChatModal,
      openChatModal,
      isAgedDebtorsReportRequestCollapsed: ref(true),
      isMissingInvoicesCompletionCollapsed: ref(true),
      isBalanceAlignmentCollapsed: ref(true),
      activeHoverToggleArea: ref(null),
      checkFillColor: '#FFFFFF',
      ACTIONS,
      balanceAlignmentActiveTab: ref(0),
      documents,
      documentsLoading,
      updateReconciliation,
      balancePayment: computed(() => balancePayments.value[0]),
      balancePaymentsRefetch,
      openSnoozeModal: ref(false),
      patchTask,
      continueToNextTask,
      reconciliationTemplateParams: ref(null),
      isAdmin,
      tasks: computed(() => [
        ...(handleReconciliationTasks.value ?? []).filter(
          ({ data: { reconciliationId } }) => reconciliationId === latestReconciliation.value?.id
        ),
        ...(supplierCreationTasks.value ?? []).filter(
          ({ data: { supplierId } }) => supplierId === latestReconciliation.value?.supplierId
        ),
        ...(uncertainBillingTasks.value ?? []).filter(({ data: { supplierId, uncertainBillingId } }) => {
          const uncertainBilling = (uncertainBillings.value ?? []).find(({ id }) => id === uncertainBillingId);
          const fromDate = latestReconciliation.value
            ? DateTime.fromISO(latestReconciliation.value.periodStart).minus({ months: 1 }).startOf('month').toISODate()
            : null;
          const toDate = latestReconciliation.value
            ? DateTime.fromISO(latestReconciliation.value.periodEnd).plus({ months: 1 }).endOf('month').toISODate()
            : null;

          return (
            supplierId === latestReconciliation.value?.supplierId &&
            fromDate <= uncertainBilling?.date &&
            toDate >= uncertainBilling?.date
          );
        }),
        ...(balanceAlignmentTasks.value ?? []).filter(({ data: { reconciliationIds } }) =>
          reconciliationIds.includes(latestReconciliation.value?.id)
        ),
      ]),
      tasksRefetch,
      originalSkipButton: ref(null),
      originalCompleteButton: ref(null),
    };
  },
  computed: {
    actionsDisabled() {
      return this.statusReflectionForm.update === null;
    },
    skipActionDisabled() {
      return !this.isBalanceValidationChecked;
    },
    taskComment() {
      return this.currentTask.data?.comment ?? '';
    },
    statusReflectionDisabled() {
      return !this.statusReflectionForm.update;
    },
    balanceAlignmentTabs() {
      return [
        {
          text: this.$t('tasks.balanceAlignmentTask.content.agedDebtorsReportByClarity'),
        },
        {
          text: this.$t('tasks.balanceAlignmentTask.content.reconciliationDocumentByClarity'),
        },
      ];
    },
    balanceAlignmentToDate() {
      return this.latestReconciliation.id ? this.formatDate(this.latestReconciliation.periodEnd) : '';
    },
    statusReflection() {
      return this.latestReconciliation?.statusReflection;
    },
  },
  watch: {
    latestReconciliation(newReconciliation, oldReconciliation) {
      if (newReconciliation && newReconciliation.id !== oldReconciliation?.id) {
        this.statusReflectionForm.update = null;
        this.statusReflectionForm.reason = newReconciliation.statusReflection?.reason ?? '';
        this.templates.forEach((template) => {
          template.type !== 'other' &&
            template.type !== 'balanceAlignmentMissingDocuments' &&
            (template.missingData = {
              [template.type]: {
                reconciliationPeriodStart: DateTime.fromISO(newReconciliation.periodStart).toFormat('yyyy-MM-dd'),
                reconciliationPeriodEnd: DateTime.fromISO(newReconciliation.periodEnd).toFormat('yyyy-MM-dd'),
                reconciliationDate: DateTime.fromISO(newReconciliation.periodEnd)
                  .plus({ days: 1 })
                  .toFormat('yyyy-MM-dd'),
              },
            }),
            (template.text = this.$t(`requests.requestsButton.popover.templates.${template.type}.text`, {
              date: this.formatDate(newReconciliation.periodEnd),
            }));
        });
      }
    },
    'statusReflectionForm.update'() {
      this.validationError = false;
    },
    task(newTask, oldTask) {
      if (newTask?.id !== oldTask?.id) {
        this.actions = {
          balanceVerification: newTask.data?.subTasks?.balanceVerification ?? false,
          missingInvoicesCompletion: newTask.data?.subTasks?.missingInvoicesCompletion ?? false,
          balanceAlignment: newTask.data?.subTasks?.balanceAlignment ?? false,
        };
        this.balanceAlignmentActiveTab = 0;
        this.balanceAlignmentNote = '';
        this.templates = this.templates.map((template) => ({
          ...template,
          selected: false,
        }));
      }
    },
  },
  updated() {
    document.querySelectorAll('.collapse').forEach((item) => {
      item.addEventListener('show.bs.collapse', ({ target: { id } }) => {
        this.updateCollapseState(id, false);
      });
      item.addEventListener('hide.bs.collapse', ({ target: { id } }) => {
        this.updateCollapseState(id, true);
      });
    });
  },
  methods: {
    async validateTaskHook() {
      const warnings = [],
        errors = [];
      this.handleValidateStatusReflection();
      if (this.validationError) {
        errors.push(this.$t(VALIDATION_ERRORS.STATUS_REFLECTION_EMPTY));
      }

      if (!this.balancePayment) {
        errors.push(this.$t('tasks.balanceAlignmentTask.initialBalanceMissing'));
      }

      if (this.reconciliations.some((reconciliation) => isNil(reconciliation.balance))) {
        errors.push(this.$t('tasks.balanceAlignmentTask.balanceMissing'));
      }

      if (!this.reconciliations.length) {
        console.error(`Balance alignment task failed to close, no reconciliations to update. task: ${this.task.id}`);
        errors.push(this.$t('errors.oopsTitle'));
      }

      if (!warnings.length && !errors.length) {
        try {
          const updatedReconciliations = await Promise.all(
            this.reconciliations.map(({ id, balance }) =>
              this.updateReconciliation({
                id,
                patchParams: {
                  balanceAlignment: reject(isNil, {
                    validated: true,
                    amount: balance,
                    note: this.balanceAlignmentNote,
                  }),
                },
              })
            )
          );

          if (!updatedReconciliations.length) {
            console.error(`Balance alignment task failed to close, no reconciliations updated. task: ${this.task.id}`);
            errors.push(this.$t('errors.oopsTitle'));
          } else {
            const [invalidBalanceAlignments, validBalanceAlignments] = partition(
              ({ data }) => data.reconciliationUpdate.balanceAlignment.validated === false,
              updatedReconciliations
            );

            if (invalidBalanceAlignments.length) {
              console.error(
                `Balance alignment task failed to close, updated reconciliations BA is invalid. 
              task: ${this.task.id},
              invalid updated Reconciliations: ${invalidBalanceAlignments.map(
                ({ data }) => data.reconciliationUpdate.id
              )},
              valid updated Reconciliations: ${validBalanceAlignments.map(({ data }) => data.reconciliationUpdate.id)}`
              );
              errors.push(this.$t('errors.oopsTitle'));
            }
          }
        } catch (error) {
          console.error(`BA task failed to update reconciliations - ${error}`);
          errors.push(this.$t('errors.oopsTitle'));
        }
      }

      if (!warnings.length && !errors.length) {
        try {
          await Promise.all([
            this.patchTask({
              taskId: this.task.id,
              patchParams: { data: { ...this.task.data, comment: this.task.data.comment } },
            }),
            this.statusReflectionForm.update && this.updateStatusReflection(),
          ]);
        } catch (error) {
          console.error(`BA task failed to update task/reflection - ${error}`);
          errors.push(this.$t('errors.oopsTitle'));
        }
      }

      return { warnings, errors };
    },
    async completedTask() {
      this.isBalanceValidationChecked = false;
      this.$emit('on-task-complete');
    },
    onTaskSkip() {
      this.statusReflectionForm.update && this.updateStatusReflection();
      this.isBalanceValidationChecked = false;
      this.$emit('on-task-skip');
    },
    onRequestMissingEvents(missingEvents) {
      const templateIndex = this.templates.findIndex(
        (template) => template.type === REQUEST_TEMPLATES_TYPE.balanceAlignmentMissingDocuments
      );

      this.templates = this.templates.map((template) => ({
        ...template,
        selected: false,
      }));

      this.templates.splice(templateIndex, 1, {
        ...this.templates[templateIndex],
        selected: true,
        text: [
          this.$tc(
            `requests.requestsButton.popover.templates.balanceAlignmentMissingDocuments.generatedMainTitle`,
            missingEvents.length,
            {
              count: missingEvents.length,
              month: DateTime.fromISO(this.latestReconciliation.periodEnd).toFormat('LLLL'),
            }
          ),
          this.$t(`requests.requestsButton.popover.templates.balanceAlignmentMissingDocuments.generatedSubTitle`),
          ...missingEvents.map((missingEvent) =>
            this.$t(`requests.requestsButton.popover.templates.balanceAlignmentMissingDocuments.generatedText`, {
              date: DateTime.fromISO(missingEvent.date).toFormat('dd.MM.yyyy'),
              ref: missingEvent.documentNumber,
            })
          ),
        ].join('\n'),
        missingData: {
          [this.templates[templateIndex].type]: {
            documents: missingEvents.map((missingDoc) => {
              return {
                docNumber: missingDoc.documentNumber,
                docType: 'taxInvoice_creditNote',
                docDate: DateTime.fromISO(missingDoc.date).toFormat('yyyy-MM-dd'),
              };
            }),
          },
        },
      });
    },
    closeRequests() {
      this.templates = this.templates.map((template) => ({
        ...template,
        selected: false,
        text: this.$t(`requests.requestsButton.popover.templates.${template.type}.text`, {
          date: this.balanceAlignmentToDate,
        }),
      }));
    },
    onTemplateSelected(type) {
      this.templates = this.templates.map((template) => ({
        ...template,
        selected: template.type === type,
      }));
    },
    updateCollapseState(id, state) {
      switch (id) {
        case ACTIONS.BALANCE_VERIFICATION:
          this.isAgedDebtorsReportRequestCollapsed = state;
          break;
        case ACTIONS.MISSING_INVOICES_COMPLETION:
          this.isMissingInvoicesCompletionCollapsed = state;
          break;
        case ACTIONS.BALANCE_ALIGNMENT:
          this.isBalanceAlignmentCollapsed = state;
          break;
        default:
          break;
      }
    },
    isCollapsed(action) {
      switch (action) {
        case ACTIONS.BALANCE_VERIFICATION:
          return this.isAgedDebtorsReportRequestCollapsed;
        case ACTIONS.MISSING_INVOICES_COMPLETION:
          return this.isMissingInvoicesCompletionCollapsed;
        case ACTIONS.BALANCE_ALIGNMENT:
          return this.isBalanceAlignmentCollapsed;
        default:
          return;
      }
    },
    openContactPopover(user) {
      this.contactPopover = true;
      this.contactUser = user;
    },
    onClickOutside() {
      this.contactPopover = false;
    },
    setLayoutLoading(bool) {
      if (bool) {
        this.closeChatModal();
      }
      this.layoutLoading = bool;
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },
    handleReconciliationDateClick(id) {
      this.pickedReconciliationId = id;
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    handleChatOpen({ supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily }) {
      const formattedPeriod = this.formatDateShort(reconciliationPeriod, isDaily);
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        reconciliationId,
        formattedPeriod,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })
        : '-';
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: 'numeric',
      });
    },
    formatReconciliationDate(periodStart, periodEnd) {
      if (!periodStart || !periodEnd) return '';
      if (periodStart === periodEnd)
        return new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(periodStart).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
      });
    },
    formatDateLong(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: 'numeric',
      });
    },
    formatMoneyAbs(value) {
      return this.formatCentsToCurrency(Math.abs(Number(value))) ?? '-';
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    handleOpenDocument(documentId) {
      this.displayedDocumentId = documentId;
    },
    getMissingType(document) {
      const documentTypesText = document.type
        .map((type) => this.$t(`document.exports.schema.type.shortName.${type}`))
        .join(' / ');
      const documentNumber = document.documentNumber ?? '';
      return `${documentTypesText} ${documentNumber}`;
    },
    getParentDocumentText(parentDocument) {
      if (!parentDocument) return;
      const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: '2-digit',
      });
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      return `${documentTypeText} ${documentDateText}`;
    },
    updateStatusReflection() {
      this.updateReconciliation({
        id: this.latestReconciliation.id,
        patchParams: { statusReflection: { reason: this.statusReflectionForm.reason } },
      });
    },
    handleValidateStatusReflection() {
      this.validationError = !validateStatusReflection(this.$refs.statusReflection);
    },
    handleActionStateChangeClick(key) {
      this.actions[key] = !this.actions[key];
    },
    openReconciliationSettings() {
      this.reconciliationTemplateParams = {
        supplierId: this.supplier.id,
        template: this.reconciliationTemplate,
      };
    },
    async taskUpdate(data) {
      this.handleTaskUpdate(data);
      this.tasksRefetch();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$gray: #f3f3f4;
$darken-gray: #e3e4e6;

.text-gray {
  color: #46494f;
}

.details-container {
  border: 1px solid #d9dcde;
  border-radius: 4px;
}

.detail {
  color: $typography-secondary;
  width: 160px;
  min-width: 160px;
}

div[aria-expanded='true'] {
  border-bottom: 1px solid #e5e8ea;
}

.disabled {
  color: #94989f !important;
}

.link {
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

.status-override:hover {
  text-decoration: none;
}

.status-reflection {
  width: 504px;
}

.red {
  color: #bb0021;
  line-height: 1;
}

.radio-red-border ::v-deep .el-radio__inner {
  border-color: #e52044;
}

::v-deep {
  textarea {
    resize: none;
  }
  .el-radio__label {
    padding-inline-start: 0.5rem !important;
  }
  .el-form-item {
    margin: 0;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $typography-primary;
  }
  .el-radio__inner {
    border-width: 2px;
  }
}

.radio-button-wrapper {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-button-action:hover {
  background: #6596f7;
  border-radius: 4px;
}

.checked-background {
  background: #306feb;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.unchecked-background {
  border-radius: 50%;
  max-width: 16px;
  height: 16px;
  &-hover {
    background: #bbbec2;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
}
.inner-white-circle {
  background: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #d2d4d7;
}

.balance-alignment-task {
  font-size: $font-size-medium;
}
</style>
